<script setup>
import { onMounted, ref, computed, watch, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useWindowHeight } from '@/util/common-utils';
import { decryptData } from '@/dataService/dataService';
import { commonActionJitsi } from '@/util/common-constant';
import { useI18n } from 'vue-i18n';
const { windowHeight } = useWindowHeight();
const api = ref(null);
const { state } = useStore();
const itemValue = computed(() => state.coordinator.itemValue || '');
const tokenJoin = computed(() => state.coordinator.tokenJoin || '');
const domain = computed(() => state.coordinator.domain || '');
const userInfo = computed(() => state.auth.userInfo || '');
const { dispatch } = useStore();
const emit = defineEmits(['leaveRoom', 'openChat']);
const isLeaveRoom = ref(false);
const isSpaceHolding = ref(false);
const isShowinstruct = ref(true);
const { t } = useI18n();
const listActions = ref([
  {
    key: 'mic',
    icon: require('@/static/img/icon/mic_call_close.svg'),
    iconActive: require('@/static/img/icon/mic-call.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'video',
    icon: require('@/static/img/icon/video-call.svg'),
    iconActive: require('@/static/img/icon/video_call_active.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'chat',
    icon: require('@/static/img/icon/chat-call.svg'),
    iconActive: require('@/static/img/icon/chat-call.svg'),
    isSelect: true,
    isAction: true,
  },
  {
    key: 'close',
    icon: require('@/static/img/icon/close-call.svg'),
    iconActive: require('@/static/img/icon/close-call.svg'),
    isSelect: true,
    isAction: false,
  },
]);
const loadJitsiAPI = () => {   
  return new Promise((resolve, reject) => {
    if (window.JitsiMeetExternalAPI) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.src = `https://${domain.value}/external_api.js`;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject("Failed to load Jitsi API");
      document.head.appendChild(script);
    }
  });
};

const initJitsi = async () => {
  try {
    await loadJitsiAPI(); 

    if (!window.JitsiMeetExternalAPI) {
      throw new Error("JitsiMeetExternalAPI is not available!");
    }

    setTimeout(() => {
      isShowinstruct.value = false;
    }, 2000);

    if (!itemValue.value || !itemValue.value.dialogId || !itemValue.value.otherInfo) {
      return;
    }

    if (api.value) {
      api.value.dispose();
    }

    api.value = new window.JitsiMeetExternalAPI(domain.value, {
      jwt: tokenJoin.value,
      roomName: itemValue.value.dialogId,
      parentNode: document.getElementById("jitsi-container"),
      userInfo: {
        displayName: userInfo.value.fullName || "Unknown User",
      },
      configOverwrite: {
        startWithVideoMuted: !itemValue.value.camera,
        startWithAudioMuted: !itemValue.value.microphone,
        disableAudioLevels: !itemValue.value.speaker,
        disableInviteFunctions: true,
        disableRemoteMute: true,
        disableRemoteControl: true,
        disableProfile: true,
        prejoinPageEnabled: false,
      },
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        TOOLBAR_BUTTONS: [],
      },
    });

    api.value.addEventListener("videoConferenceLeft", () => {
      handleLeaveRoom(itemValue.value.dialogId);
    });
    api.value.addEventListener("outgoingMessage", (event) => {
      handleSendMessages(event.message);
    });
    api.value.addEventListener("participantJoined", (event) => {
    });
    console.log("Jitsi API initialized successfully!");

  } catch (error) {
    console.error(error);
  }
};
onMounted(initJitsi);
watch(
  () => itemValue.value.dialogId,
  (newDialogId, oldDialogId) => {
    if (newDialogId !== oldDialogId) {
      initJitsi();
    }
  }
);
const handleLeaveRoom = async (id) => {
  if (!api.value) return;
  await dispatch('coordinator/leaveRoom', { roomId: id });
  api.value.dispose();
  api.value = null;
  isLeaveRoom.value = true;
  emit('leaveRoom', true);
};
const handleSendMessages = (message) => {
  let mess = {
    receiverId: itemValue.value.otherInfo.peerId,
    receiverType: itemValue.value.otherInfo.peerType,
    messageType: 'text',
    content: message,
    forwarded: false,
  };
  dispatch('coordinator/sendMessages', mess).then((data) => {});
};
onUnmounted(() => {
  // handleLeaveRoom(itemValue.value.dialogId);
  if (!isLeaveRoom.value) {
    dispatch('coordinator/showMiniCall', true);
    localStorage.setItem('isLeaveRoom', isLeaveRoom.value);
  }
});

const getSelectState = (key) => {
  const item = listActions.value.find((action) => action.key === key);
  return item ? item.isSelect : false;
};
const handleSelectItem = (key) => {
  if (key !== 'mic') {
    const item = listActions.value.find((action) => action.key === key);
    if (!item) return;

    item.isSelect = !item.isSelect;
    if (api.value && commonActionJitsi[item.key]) {
      api.value.executeCommand(commonActionJitsi[item.key]);
    }
    dispatch('coordinator/updateStatusCall', {
      roomId: itemValue.value.dialogId,
      camera: getSelectState('video'),
      microphone: getSelectState('mic'),
      screenShare: false,
      userId: itemValue.value.otherInfo.peerId,
    });
  }
};
const handleMicAction = (actionMic) => {
  const item = listActions.value.find((action) => action.key === 'mic');
  if (!item) return;
  item.isSelect = actionMic;
  if (api.value && commonActionJitsi[item.key]) {
    api.value.executeCommand(commonActionJitsi[item.key]);
  }
  dispatch('coordinator/updateStatusCall', {
    roomId: itemValue.value.dialogId,
    camera: getSelectState('video'),
    microphone: actionMic,
    screenShare: false,
    userId: itemValue.value.otherInfo.peerId,
  });
};
const handleHoldStart = (key) => {
  if (key === 'mic') {
    handleMicAction(false);
  }
};

const handleHoldEnd = (key) => {
  if (key === 'mic') {
    handleMicAction(true);
  }
};
const handleKeyDown = (event) => {
  if (event.code === 'Space' && !isSpaceHolding.value) {
    isSpaceHolding.value = true;
    handleMicAction(false);
  }
};
const handleKeyUp = (event) => {
  if (event.code === 'Space') {
    isSpaceHolding.value = false;
    handleMicAction(true);
  }
};
onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown);
  window.removeEventListener('keyup', handleKeyUp);
});
</script>

<template>
  <div
    class="relative mt-[10px]"
    :style="{ height: `${windowHeight - 280}px` }"
  >
    <div id="jitsi-container" class="w-full h-[88%]"></div>
    <div class="h-[12%] w-full bg-ems-gray800 flex items-end justify-start">
      <div
        class="h-[72px] w-full bg-ems-gray700 rounded-2xl flex items-center justify-around select-none relative"
      >
        <div
          v-for="(item, index) in listActions"
          :key="index"
          :class="`h-[48px] w-[48px] rounded-full flex items-center justify-center cursor-pointer select-none ${
            item.isAction
              ? item.isSelect
                ? 'bg-ems-gray500'
                : 'bg-white'
              : 'bg-ems-main3'
          }`"
          @click="handleSelectItem(item.key)"
          @mousedown="handleHoldStart(item.key)"
          @mouseup="handleHoldEnd(item.key)"
        >
          <img
            alt="icon"
            :src="item.isSelect ? item.icon : item.iconActive"
            class="select-none"
          />
          <div
            v-if="isShowinstruct"
            class="absolute top-[-50px] -translate-x-1/2 bg-gray-800 text-white text-sm px-3 py-1 rounded-md shadow-md left-[10px]"
          >
            {{t('coordinator.instruct')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
